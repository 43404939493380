import ios from '../assets/ios.jpg'
import android from '../assets/android.jpg'
export let en = [
    {
        title: '1. Changes to video audio and subtitles',
        content: `Android users: You can find "Audio and Subtitles" at the bottom of the full-screen playback page, where you can change the audio and subtitles you want.<img style="display:block;width:100%" src="${android}"/></br>
        iOS users: You can find the "A" logo in the upper right corner of the full-screen playback page. Switch between audio and subtitles with a single click<img style="display:block;width:100%" src="${ios}"/>`
    },
    {
        title: '2. Video cannot be played',
        content: `If the video or ad you want to watch cannot be viewed/always loading, you can try clearing the cache, restarting the app, changing networks, or turning off the VPN. </br>
        If the above method does not solve the problem, please click this link to download and install the application, the problem can be solved in the latest version.Or please contact me via Telegram +852 5383 2085</br>
        <a style="color: #3f86ee;display: block;" href="https://f34g9.nt73e.com/share/hotel-business/index_y.html">https://f34g9.nt73e.com/share/hotel-business/index_y.html</a>`
    },
    {
        title: '3. Video quality options',
        content: `Sorry, modifying video quality is currently not supported，this feature is in the research stage and is worth looking forward to`
    },
    {
        title: '4. TV version app or PC',
        content: `Sorry, we are currently developing TV and PC versions of the app, please be patient.`
    },
    {
        title: '5. Search videos',
        content: `You can search for videos via the search box at the top of the app's homepage. If you can't find the video, please add my telegram: +852 5383 2085 and send me the title of the video. If we have the resources, we will replace or add the video for you in time.`
    },
    {
        title: '6. How to download videos',
        content: `There is a download option to the right of the video name. Every time you watch an ad,you will get additional download opportunities. Click the corresponding episode again to download it.`
    },
    {
        title: '7. App download link',
        content: `You can download the Android and ios version installation package through this link</br>
        <a style="color: #3f86ee;display: block;" href="https://f34g9.nt73e.com/share/hotel-business/index_y.html">https://f34g9.nt73e.com/share/hotel-business/index_y.html</a>`
    },
]
export let hi = [
    {
        title: '1. वीडियो ऑडियो और उपशीर्षक में परिवर्तन',
        content: `एंड्रॉइड उपयोगकर्ता: आप पूर्ण-स्क्रीन प्लेबैक पृष्ठ के नीचे "ऑडियो और उपशीर्षक" पा सकते हैं, जहां आप अपने इच्छित ऑडियो और उपशीर्षक बदल सकते हैं।.<img style="display:block;width:100%" src="${android}"/></br>
        iOS उपयोगकर्ता: आप पूर्ण-स्क्रीन प्लेबैक पृष्ठ के ऊपरी दाएं कोने में "ए" लोगो पा सकते हैं। एक क्लिक से ऑडियो और उपशीर्षक के बीच स्विच करें.<img style="display:block;width:100%" src="${ios}"/>`
    },
    {
        title: '2. वीडियो नहीं चलाया जा सकता',
        content: `यदि आप जो वीडियो या विज्ञापन देखना चाहते हैं, उसे देखा नहीं जा सकता/हमेशा लोड नहीं किया जा सकता, तो आप कैश साफ़ करने, ऐप को पुनरारंभ करने, नेटवर्क बदलने या वीपीएन बंद करने का प्रयास कर सकते हैं। </br>
        यदि उपरोक्त विधि से समस्या का समाधान नहीं होता है, तो कृपया एप्लिकेशन को डाउनलोड और इंस्टॉल करने के लिए इस लिंक पर क्लिक करें, समस्या को नवीनतम संस्करण में हल किया जा सकता है। या कृपया टेलीग्राम +852 5383 2085 के माध्यम से मुझसे संपर्क करें।</br>
        <a style="color: #3f86ee;display: block;" href="https://f34g9.nt73e.com/share/hotel-business/index_y.html">https://f34g9.nt73e.com/share/hotel-business/index_y.html</a>`
    },
    {
        title: '3. वीडियो गुणवत्ता विकल्प',
        content: `क्षमा करें, वीडियो गुणवत्ता को संशोधित करना वर्तमान में समर्थित नहीं है, यह सुविधा अनुसंधान चरण में है और आगे देखने लायक है`
    },
    {
        title: '4. टीवी संस्करण ऐप या पीसी',
        content: `क्षमा करें, हम वर्तमान में ऐप के टीवी और पीसी संस्करण विकसित कर रहे हैं, कृपया धैर्य रखें।`
    },
    {
        title: '5. वीडियो खोजें',
        content: `आप ऐप के मुखपृष्ठ के शीर्ष पर स्थित खोज बॉक्स के माध्यम से वीडियो खोज सकते हैं। यदि आपको वीडियो नहीं मिल रहा है, तो कृपया मेरा टेलीग्राम जोड़े: +852 5383 2085 और मुझे वीडियो का शीर्षक भेजें। यदि हमारे पास संसाधन हैं, तो हम समय पर आपके लिए वीडियो बदल देंगे या जोड़ देंगे।`
    },
    {
        title: '6. वीडियो कैसे डाउनलोड करें',
        content: `वीडियो नाम के दाईं ओर एक डाउनलोड विकल्प है। हर बार जब आप कोई विज्ञापन देखेंगे, तो आपको अतिरिक्त डाउनलोड के अवसर मिलेंगे। इसे डाउनलोड करने के लिए संबंधित एपिसोड पर दोबारा क्लिक करें।`
    },
    {
        title: '7. ऐप डाउनलोड लिंक',
        content: `आप इस लिंक के माध्यम से एंड्रॉइड और आईओएस संस्करण इंस्टॉलेशन पैकेज डाउनलोड कर सकते हैं</br>
        <a style="color: #3f86ee;display: block;" href="https://f34g9.nt73e.com/share/hotel-business/index_y.html">https://f34g9.nt73e.com/share/hotel-business/index_y.html</a>`
    },
]