<template>
  <div class="wrap">
    <h1 class="title">{{ question_title }}</h1>
    <div class="change-language" @click="changeLanguageOverlayShow = true">
      {{ locale == "en" ? "English" : " हिंदी" }}
      <van-icon style="margin-left: 6px" name="arrow-down" />
    </div>
    <van-collapse v-model="activeNames" accordion>
      <van-collapse-item
        :name="i"
        v-for="(item, i) in question_list"
        :key="i"
        :disabled="item.content == '' ? true : false"
      >
        <template v-if="item.content == ''" #right-icon> </template>
        <template #title>
          <h1 style="font-size: 16px; font-weight: 400">{{ item.title }}</h1>
        </template>
        <p v-html="item.content"></p>
        <a
          :href="item.href"
          style="color: #3f86ee; display: block"
          v-if="item.href"
          >{{ item.href }}</a
        >
      </van-collapse-item>
    </van-collapse>
    <!-- 更换语言 -->
    <van-overlay
      :z-index="999"
      :show="changeLanguageOverlayShow"
      @click="changeLanguageOverlayShow = false"
    >
      <div class="wrapper" @click.stop>
        <div class="change-language-overlay">
          <div class="change-language-overlay-title">Select Language</div>
          <div class="change-language-overlay-content">
            <van-radio-group checked-color="red" v-model="locale">
              <van-cell-group>
                <van-cell
                  class="change-language-overlay-content-call"
                  v-for="(val, key) in appLanguageDict"
                  :key="val"
                  :title="key"
                  clickable
                  @click="locale = val"
                >
                  <template #right-icon>
                    <van-radio :name="val" />
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
          </div>
          <div class="change-language-overlay-footer">
            <div
              class="change-language-overlay-footer-button"
              @click="changeLanguageOverlayShow = false"
            >
              Cancel
            </div>
            <div
              class="change-language-overlay-footer-button change-language-overlay-footer-button-active"
              @click="changeAppLanguage"
            >
              Save
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { en, hi } from "../../utils/question";
// import { getAppLanguage } from "@/utils/init";
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      question_list: en,
      activeNames: "1",
      question_title: "",
      changeLanguageOverlayShow: false,
      appLanguageDict: {
        English: "en",
        हिंदी: "hi",
      },
      locale: "en",
    };
  },
  mounted() {
    this.changeAppLanguage();
  },
  methods: {
    changeAppLanguage() {
      if (this.locale == "en") {
        this.question_list = en;
        this.question_title = "Common Problem";
      } else {
        this.question_title = "सामान्य समस्या";
        this.question_list = hi;
      }
      this.changeLanguageOverlayShow = false;
    },
    imgView(src) {
      ImagePreview([src]);
    },
  },
};
</script>

<style scoped>
* {
  color: #fff;
}

h1,
p {
  margin: 0;
}

p {
  color: #969799;
}

.title {
  font-size: 20px;
  text-align: center;
  line-height: 60px;
  margin-bottom: 30px;
  color: #fff;
}

/deep/ .van-cell {
  background: transparent;
}

/deep/ .van-collapse-item__content {
  background: transparent;
}

/deep/ .van-hairline--top-bottom::after {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.change-language {
  position: fixed;
  right: 10px;
  top: 14px;
  font-size: 12px;
  display: flex;
  align-items: center;
  border: 1px solid #fff;
  padding: 5px 10px;
  border-radius: 4px;
  background: #020d19;
  z-index: 2;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.change-language-overlay {
  width: 250px;
  background-color: var(--main-color);
  border-radius: 5px;
}

.change-language-overlay-title {
  background-color: var(--minor-color);
  height: 50px;
  font-size: 20px;
  color: var(--title-color);
  align-content: center;
  text-align: center;
  line-height: 50px;
}

.change-language-overlay-footer {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
}

.change-language-overlay-content-call {
  background-color: var(--main-color);
  color: var(--title-color);
}

.change-language-overlay-footer-button {
  font-size: 16px;
  color: red;
  text-align: center;
  line-height: 32px;
  border-radius: 16px;
  border: 1px solid red;
  width: 100px;
}

.change-language-overlay-footer-button-active {
  background-color: red;
  color: white;
  border: 1px solid red;
}
</style>
